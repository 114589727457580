import "./instrument";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import App from './App';
import NewContainerFlow from './containers/NewContainerFlow';
import MyApp from './containers/MyApp';
import MyAppOverview from './containers/MyAppOverview';
import MyAppDeployments from './containers/MyAppDeployments';
import MyAppLogs from './containers/MyAppLogs';
import MyAppMetrics from './containers/MyAppMetrics';
import MyAppSettings from './containers/MyAppSettings';
import MyApps from './containers/MyApps';
import MyAgents from './containers/MyAgents';
import { Toaster } from 'react-hot-toast';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
        <Route index element={<Navigate replace to="/apps" />} />
          <Route path="new-container" element={<NewContainerFlow />} />
          <Route path="apps" element={<MyApps />} />
          <Route path="apps/:appId/" element={<MyApp />}>
            <Route index element={<MyAppOverview />} />
            <Route path="deployments" element={<MyAppDeployments />} />
            <Route path="logs" element={<MyAppLogs />} />
            <Route path="metrics" element={<MyAppMetrics />} />
            <Route path="settings" element={<MyAppSettings />} />
          </Route>
          <Route path="agents" element={<MyAgents />} />
          <Route path="agents/:agentId/" element={<MyAgents />} />
        </Route>        
      </Routes>
    </BrowserRouter>
    <Toaster />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
