export const PARSE_DASHBOARD_URL = process.env.REACT_APP_BACK4APP2_DASHBOARD_PARSE_DASHBOARD_URL || 'http://localhost:8000';
export const NEW_PARSE_DASHBOARD_URL = process.env.REACT_APP_BACK4APP2_NEW_PARSE_DASHBOARD_URL || 'http://localhost:8000';
export const CONTAINERS_DASHBOARD_URL = process.env.REACT_APP_BACK4APP2_DASHBOARD_CONTAINERS_DASHBOARD_URL || 'http://localhost:3030';
export const BACK4APP_DOT_COM_SITE_URL = process.env.REACT_APP_BACK4APP2_DASHBOARD_BACK4APP_DOT_COM_SITE_URL || 'http://localhost:3000';
export const BACK4APP_DOT_COM_OLD_SITE_URL = process.env.REACT_APP_BACK4APP2_DASHBOARD_BACK4APP_DOT_COM_OLD_SITE_URL || 'https://www.back4app.com';
export const BACK4APP_DOT_COM_DASHBOARD_URL = process.env.REACT_APP_BACK4APP2_DASHBOARD_BACK4APP_DOT_COM_DASHBOARD_URL || 'http://localhost:4000';
export const API_HTTP_URL = process.env.REACT_APP_BACK4APP2_DASHBOARD_API_HTTP_URL || 'http://localhost:4040';
export const API_WS_URL = process.env.REACT_APP_BACK4APP2_DASHBOARD_API_WS_URL;
export const GITHUB_APP_URL = process.env.REACT_APP_BACK4APP2_DASHBOARD_GITHUB_APP_URL || 'https://github.com/apps/back4app-dev-app/installations/new';
export const AMPLITUDE_KEY = process.env.REACT_APP_BACK4APP2_DASHBOARD_AMPLITUDE_KEY || '';
export const LOGROCKET_URL = process.env.REACT_APP_BACK4APP2_DASHBOARD_LOGROCKET_URL || '';
export const SOLUCX_API_KEY = process.env.REACT_APP_BACK4APP2_SOLUCX_API_KEY || '';
